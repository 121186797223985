import clsx from 'clsx';
import { ReactNode } from 'react';

import { DirectBriefingButton } from '_components/Briefing/versions/DirectBriefing/button';

import * as S from './styles';

type Props = {
  title: ReactNode | string;
  subtitle: ReactNode | string;
  className?: string;
  icon: ReactNode;
  btnLabel: string;
  longtailsPage?: boolean;
  agencySlug?: string;
  element: string;
};

export const CtaBox: React.FC<Props> = (props) => {
  const { title, subtitle, className, icon, btnLabel, longtailsPage, agencySlug, element } = props;

  return (
    <S.Shape
      className={clsx(
        className,
        'layout-column layout-gt-xs-row layout-align-center-center layout-align-start-center rounded-md bg-secondary-100',
      )}>
      <header className="p-16">{icon}</header>
      <div className="flex layout-colum p-16">
        <span className="bold mb-4">{title}</span>
        <p className="small">{subtitle}</p>
      </div>
      <footer className="p-16">
        <DirectBriefingButton
          page={longtailsPage ? 'longtail' : undefined}
          cta={longtailsPage ? 'work-modal' : element}
          agencySlug={agencySlug as string}
          size={'md'}
          buttonStyle="primary"
          buttonVariant="raised"
          label={btnLabel}
        />
      </footer>
    </S.Shape>
  );
};
