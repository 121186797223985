import { Trans } from '@sortlist-frontend/translation/ssr';

import { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';

export const getFormatedMarketType = (e: string | null, separator: string) => {
  const array = e?.split('_');

  return array?.map((item) => item.toUpperCase()).join(separator);
};

export const formatDate = (date: string, locale: string, options?: Intl.DateTimeFormatOptions): string | null => {
  try {
    const intlDate = new Intl.DateTimeFormat(
      locale,
      options || {
        year: 'numeric',
        month: 'short',
      },
    );
    return intlDate.format(new Date(date));
  } catch (e) {
    return null;
  }
};

// HOIST OUTSIDE OF COMPONENT
type GetWorkDateParams = {
  start_date: string | null;
  end_date: string | null;
  /** */
  ongoingText?: string;
  locale?: string;
};

export const getWorkRangeDate = (params: GetWorkDateParams): string => {
  const { start_date: startDate, end_date: endDate = null, ongoingText, locale = 'en' } = params;
  const start = startDate && formatDate(startDate, locale);
  const end = endDate && formatDate(endDate, locale);
  if (!start && end) return `${end}`;
  if (start && !end) return `${start} - ${ongoingText}`;
  if (start && start === end) return `${start}`;
  if (start) return `${start} - ${end}`;
  return '';
};

export const getWorkSingleDate = (params: GetWorkDateParams): string | null => {
  const { start_date: startDate, end_date: endDate } = params;

  if (!startDate && !endDate) return null;

  const start = startDate && new Date(startDate).getFullYear();
  const end = endDate && new Date(endDate).getFullYear();

  return end ? `${end}` : `${start}`;
};

export const getWorkDescriptions = (client: string, sector: string, marketType: string | null, year: string | null) => {
  if (marketType) {
    const audience = getFormatedMarketType(marketType, ' / ');

    return {
      descriptionYear: (
        <Trans i18nKey={'portfolio:work.descriptionYear'}>
          Project made for <b>{{ client }}</b> in the <b>{{ sector }}</b> industry for a <b>{{ audience }}</b> audience
          in <b>{{ year }}</b>.
        </Trans>
      ),
      descriptionYearOngoing: (
        <Trans i18nKey={'portfolio:work.descriptionYearOngoing'}>
          Ongoing project for <b>{{ client }}</b> in the <b>{{ sector }}</b> industry for a <b>{{ audience }}</b>&nbsp;
          audience since <b>{{ year }}</b>.
        </Trans>
      ),
      description: (
        <Trans i18nKey={'portfolio:work.description'}>
          Project made for <b>{{ client }}</b> in the <b>{{ sector }}</b> industry for a <b>{{ audience }}</b> audience.
        </Trans>
      ),
    };
  }

  return {
    descriptionYear: (
      <Trans i18nKey={'portfolio:work.descriptionYearNoAudience'}>
        Project made for <b>{{ client }}</b> in the <b>{{ sector }}</b> industry in <b>{{ year }}</b>.
      </Trans>
    ),
    descriptionYearOngoing: (
      <Trans i18nKey={'portfolio:work.descriptionYearOngoingNoAudience'}>
        Ongoing project for <b>{{ client }}</b> in the <b>{{ sector }}</b> industry since <b>{{ year }}</b>.
      </Trans>
    ),
    description: (
      <Trans i18nKey={'portfolio:work.descriptionNoAudience'}>
        Project made for <b>{{ client }}</b> in the <b>{{ sector }}</b> industry.
      </Trans>
    ),
  };
};

export const getWorkDescription = (work: GetAgencyPortfolio['works'][0]) => {
  const { company_name: companyName, end_date: endDate, market_type: marketType, sector_name: sectorName } = work;

  const date = getWorkSingleDate({
    start_date: work.start_date,
    end_date: endDate,
  });

  if (!sectorName || !companyName) return;
  const { descriptionYear, descriptionYearOngoing, description } = getWorkDescriptions(
    companyName,
    sectorName,
    marketType,
    date,
  );

  if (date) {
    return endDate ? descriptionYear : descriptionYearOngoing;
  }
  return description;
};
