import {
  BusinessCenterTwoTone,
  BusinessTwoTone,
  EventTwoTone,
  GroupTwoTone,
  InsertLink,
  LocationOnTwoTone,
  PublicTwoTone,
  SettingsTwoTone,
} from '@mui/icons-material';
import { Divider } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC, Fragment, ReactNode } from 'react';

import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { usePublicAppContext } from '_core/context/public-app-context';

import { workDetailModalConfig } from '../config';
import { PortfolioWork } from '../portfolio.api';
import { getFormatedMarketType, getWorkRangeDate } from '../utils';

type Props = {
  work: PortfolioWork;
  agencyBrandingPlan: string;
};

type AboutSectionData = {
  id: string;
  title: string;
  info?: string | string[] | null | ReactNode;
  icon: ReactNode;
};

export const WorkAboutPanel: FC<Props> = (props) => {
  const { agencyBrandingPlan, work } = props;
  const { t } = useTranslation(workDetailModalConfig.i18nNamespaces);
  const { locale } = usePublicAppContext();

  const aboutSectionData: AboutSectionData[] = [
    {
      id: 'client',
      title: t('portfolio:work.client'),
      info: work.company_name,
      icon: <BusinessCenterTwoTone fontSize="small" className="text-secondary-700" />,
    },
    {
      id: 'location',
      title: t('portfolio:work.location'),
      info: work.company_address,
      icon: <LocationOnTwoTone fontSize="small" className="text-secondary-700" />,
    },
    {
      id: 'sector',
      title: t('portfolio:work.sector'),
      info: work.sector_name,
      icon: <BusinessTwoTone fontSize="small" className="text-secondary-700" />,
    },
    {
      id: 'audience',
      title: t('portfolio:work.audience'),
      info: getFormatedMarketType(work.market_type, ', '),
      icon: <GroupTwoTone fontSize="small" className="text-secondary-700" />,
    },
    {
      id: 'expertises',
      title: t('portfolio:work.expertise'),
      info: work.expertises,
      icon: <SettingsTwoTone fontSize="small" className="text-secondary-700" />,
    },
    {
      id: 'scope',
      title: t('portfolio:work.scope'),
      info: work.market_size ? work.market_size.charAt(0).toUpperCase() + work.market_size.slice(1) : undefined,
      icon: <PublicTwoTone fontSize="small" className="text-secondary-700" />,
    },
    {
      id: 'date',
      title: t('portfolio:work.date'),
      info:
        work !== null
          ? getWorkRangeDate({
              start_date: work.start_date,
              end_date: work.end_date,
              ongoingText: t('portfolio:work.ongoing'),
              locale: locale,
            })
          : '',
      icon: <EventTwoTone fontSize="small" />,
    },
    {
      id: 'link',
      title: t('portfolio:work.link'),
      info: work.link && (
        <Obfuscate className="small text-primary-900 underline" href={work.link} obfuscate={true} target="_blank">
          {t('portfolio:work.linkCta')}
        </Obfuscate>
      ),
      icon: <InsertLink fontSize="small" />,
    },
  ].filter((section) => !!section.info);

  return (
    <>
      {aboutSectionData.map((section, index) => (
        <Fragment key={section.title}>
          <div className="layout-row layout-align-start-start py-16">
            <div className="flex-none mr-16 py-8 layout-column layout-align-start-center">{section.icon}</div>
            <div className="flex layout-column">
              <span className="bold small">{section.title}</span>
              {section.id === 'expertises' && (
                <span className="py-8 layout-wrap">
                  {(section.info as unknown as string[]).map((expertise: string) => {
                    return (
                      <span
                        key={expertise}
                        className="mr-8 mb-8 bg-secondary-300 rounded-xs px-8 py-4 small text-secondary-900">
                        {expertise}
                      </span>
                    );
                  })}
                </span>
              )}
              {section.id === 'link' && section.info}
              {typeof section.info !== 'object' ? <span className="small">{section.info}</span> : null}
            </div>
          </div>
          {index + 1 !== aboutSectionData.length ? <Divider /> : null}
        </Fragment>
      ))}
    </>
  );
};
