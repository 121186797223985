import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

const workDisplay = `
  width: 100%;
  height: 100%;
  border: none;
  object-fit: contain;
  object-position: top;
`;

export const Slide = styled.img`
  ${() => workDisplay}
`;

export const FrameWrapper = styled.div`
  padding-bottom: 56.25%;
  max-width: 100%;
  height: 0;
`;

export const Frame = styled.iframe`
  top: 0;
  left: 0;
  position: absolute;
  ${() => workDisplay}
`;

type SliderButtonProps = {
  direction: 'back' | 'forward';
};

const getArrowStyle = (direction: SliderButtonProps['direction']) => {
  const position = direction === 'back' ? 'left' : 'right';
  return `${position}: 0.5rem;

    @media only screen and (min-width: ${BREAKPOINTS.xs}) {
      ${position}: -1rem;
    }`;
};

export const SliderButton = styled.div`
  top: 50%;
  z-index: 1000;
  transform: translateY(-50%);
  ${(props: SliderButtonProps) => getArrowStyle(props.direction)}
`;

type DotProps = {
  active: boolean;
};

export const Dot = styled.span`
  ${(props: DotProps) => `
    opacity: ${props.active ? '100%' : '50%'};
  `}
`;

export const DotWrapper = styled.div`
  bottom: 0.5rem;
`;

const getLinearGradient = (lastSlide: boolean, firstSlide: boolean) => {
  if (lastSlide) {
    return `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 10%)`;
  } else if (firstSlide) {
    return `linear-gradient(90deg, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%)`;
  } else {
    return `linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 90%,
      rgba(255, 255, 255, 1) 100%
      )`;
  }
};

type WorkThumbnailsWrapperProps = {
  thumbnailHeight: number;
};

export const WorkThumbnailsWrapper = styled.div`
  ${(props: WorkThumbnailsWrapperProps) => `
    height: ${props.thumbnailHeight + 24}px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

type WorkThumbnailGradientProps = {
  firstSlide: boolean;
  lastSlide: boolean;
};

export const WorkThumbnailGradient = styled.div`
  ${(props: WorkThumbnailGradientProps) => `
    &::after {
      top: 0;
      left: 0;
      z-index: 1;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      background: ${getLinearGradient(props.lastSlide, props.firstSlide)};
    }
  `}
`;

type WorkThumbnailProps = {
  active: boolean;
  thumbnailWidth: number;
  thumbnailHeight: number;
};

export const WorkThumbnail = styled.img`
  ${(props: WorkThumbnailProps) => `
    object-fit: cover;
    height:  ${props.thumbnailHeight}px;
    width: ${props.thumbnailWidth}px;
    min-width: ${props.thumbnailWidth}px;
    transition: all 0.3s ease-in-out;

    &:not(:hover) {
      opacity: ${props.active ? 1 : 0.25};
      filter: ${props.active ? 'grayscale(0)' : 'grayscale(1)'};
    }
  `}
`;

type SlideWrapperProps = {
  isLoading: boolean;
  height?: number;
};

export const SlideWrapper = styled.div`
  ${(props: SlideWrapperProps) =>
    `min-height: ${
      props.isLoading
        ? // eslint-disable-next-line sonarjs/no-nested-template-literals
          `${`${props.height}px` || '32rem'}`
        : 'auto'
    };`}
`;
