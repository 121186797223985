import { useQuery } from '@tanstack/react-query';

import type { GetAgencyAwards } from '_backend/queries/get-agency-awards/get-agency-awards.types';
import type { GetAgencyOffices } from '_backend/queries/get-agency-offices/get-agency-offices.types';
import type { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import type { GetAgencyProfile } from '_backend/queries/get-agency-profile/get-agency-profile.types';
import type { GetAgencyReviews } from '_backend/queries/get-agency-reviews/get-agency-reviews.types';
import type { GetAgencySeoDetails } from '_backend/queries/get-agency-seo-details/get-agency-seo-details.types';
import type { GetAgencyServices } from '_backend/queries/get-agency-services/get-agency-services.types';
import type { GetAgencyTeam } from '_backend/queries/get-agency-team/get-agency-team.types';

export const cacheKey = {
  agencyAwards: (agencySlug: string, locale: string) => `${agencySlug}-${locale}-/api/query/agency-awards`,
  agencyProfile: (agencySlug: string, locale: string) => `${agencySlug}-${locale}-/api/query/agency-profile`,
  agencyReviews: (agencySlug: string, locale: string) => `${agencySlug}-${locale}-/api/query/agency-reviews`,
  agencySeoDetails: (agencySlug: string, locale: string) => `${agencySlug}-${locale}-/api/query/agency-seo-details`,
  agencyPortfolio: (agencySlug: string, locale: string) => `${agencySlug}-${locale}-/api/query/agency-portfolio`,
  agencyServices: (agencySlug: string, locale: string) => `${agencySlug}-${locale}-/api/query/agency-services`,
  agencyTeam: (agencySlug: string, locale: string) => `${agencySlug}-${locale}-/api/query/agency-team`,
  agencyOffices: (agencySlug: string, locale: string) => `${agencySlug}-${locale}-/api/query/agency-offices`,
};

export function useGetAgencyAwards(agencySlug: string, locale: string) {
  return useQuery({
    queryKey: [cacheKey.agencyAwards(agencySlug, locale)],
    queryFn: () => null as unknown as GetAgencyAwards,
    enabled: false,
  });
}

export function useGetAgencyProfile(agencySlug: string, locale: string) {
  return useQuery({
    queryKey: [cacheKey.agencyProfile(agencySlug, locale)],
    queryFn: () => null as unknown as GetAgencyProfile,
    enabled: false,
  });
}

export function useGetAgencyReviews(agencySlug: string, locale: string) {
  return useQuery({
    queryKey: [cacheKey.agencyReviews(agencySlug, locale)],
    queryFn: () => null as unknown as GetAgencyReviews,
    enabled: false,
  });
}

export function useGetAgencySeoDetails(agencySlug: string, locale: string) {
  return useQuery({
    queryKey: [cacheKey.agencySeoDetails(agencySlug, locale)],
    queryFn: () => null as unknown as GetAgencySeoDetails,
    enabled: false,
  });
}

export function useGetAgencyPortfolio(agencySlug: string, locale: string) {
  return useQuery({
    queryKey: [cacheKey.agencyPortfolio(agencySlug, locale)],
    queryFn: () => null as unknown as GetAgencyPortfolio,
    enabled: false,
  });
}

export function useGetAgencyServices(agencySlug: string, locale: string) {
  return useQuery({
    queryKey: [cacheKey.agencyServices(agencySlug, locale)],
    queryFn: () => null as unknown as GetAgencyServices,
    enabled: false,
  });
}

export function useGetAgencyTeam(agencySlug: string, locale: string) {
  return useQuery({
    queryKey: [cacheKey.agencyTeam(agencySlug, locale)],
    queryFn: () => null as unknown as GetAgencyTeam,
    enabled: false,
  });
}

export function useGetAgencyOffices(agencySlug: string, locale: string) {
  return useQuery({
    queryKey: [cacheKey.agencyOffices(agencySlug, locale)],
    queryFn: () => null as unknown as GetAgencyOffices,
    enabled: false,
  });
}
