import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

export const ArrowForwardButton = styled.div`
  top: 50%;
  right: 2.75rem;
  transform: translateY(-1.25rem);

  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    right: 1rem;
  }
`;

export const ArrowBackwardButton = styled.div`
  top: 50%;
  left: 2.75rem;
  transform: translateY(-1.25rem);

  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    left: 1rem;
  }
`;
export const WorkLoader = styled.div`
  height: 14rem;
`;

export const AgencyLogo = styled.img`
  max-height: 4rem;
  max-width: 4rem;
`;

export const WorkCardThumbnail = styled.div`
  > img {
    object-fit: cover;
    height: 14rem;
    min-height: 14rem;
    min-width: 100%;
  }
`;
