import { AssignmentTwoTone } from '@mui/icons-material';
import { Divider, Loader, useMediaQuery } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { cleanHtml } from '@sortlist-frontend/utils';
import { FC, Fragment, useEffect } from 'react';
import { forceCheck } from 'react-lazyload';

import { Work } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import { GetWork } from '_backend/queries/get-work/get-work.types';
import { CtaBox } from '_components/common/CtaBox';
import { Carousel } from '_components/styled/carousel';

import { workDetailModalConfig } from '../config';
import { WorkAboutPanel } from '../WorkAboutPanel';
import { WorkCard } from '../WorkCard';
import { WorkShareButton } from '../WorkShareButton';

export type WorkDetailPanelProps = {
  agencyName: string;
  agencySlug: string;
  agencyBrandingPlan: string;
  isLoading: boolean;
  onClick: (work: Work) => void;
  work: GetWork | undefined | null;
  similarWorks?: Work[];
  longtailsPage?: boolean;
};

export const WorkDetailPanel: FC<WorkDetailPanelProps> = (props) => {
  const {
    agencyName,
    agencyBrandingPlan,
    agencySlug,
    isLoading,
    onClick,
    work,
    similarWorks = [],
    longtailsPage,
  } = props;
  const { t } = useTranslation(workDetailModalConfig.i18nNamespaces);
  const { media } = useMediaQuery();

  // Temp fix for lazyload to work for similar work section in modal on desktop
  useEffect(() => {
    const container = document.querySelector('.MuiDialog-container');
    if (!container) return;

    container.addEventListener('scroll', forceCheck);
    return () => {
      container.removeEventListener('scroll', forceCheck);
    };
  }, []);

  const hasDescriptionData = work?.challenges || work?.solutions || work?.impacts || work?.description;

  const descriptionDetails = [
    { header: t('portfolio:work.challenge'), details: work?.challenges },
    { header: t('portfolio:work.solution'), details: work?.solutions },
    { header: t('portfolio:work.impact'), details: work?.impacts },
  ];

  return (
    <>
      {isLoading ? (
        <div>
          <Loader size="md" color="secondary-500" message={t('portfolio:general.loading')} />
        </div>
      ) : (
        <div>
          <div className="py-32 layout-row layout-wrap">
            <div className="flex-gt-sm-70 flex-100 pl-gt-sm-64 pr-gt-sm-16 px-sm-64 mb-32 mb-gt-sm-0">
              {work?.attachments && work?.attachments.length > 0 ? (
                <Carousel name={work?.name} slides={work?.attachments} />
              ) : (
                <div className="bg-secondary-100 width-100 rounded-lg py-128 px-32 layout-align-center-center layout-column">
                  <span className="py-128 text-secondary-500">{t('portfolio:work.noImage')}</span>
                </div>
              )}
            </div>
            <div className="flex-sm-100 flex-xs-100 flex pr-gt-sm-64 pl-gt-sm-16 px-sm-64 px-xs-16">
              <div className="layout-row layout-align-space-between-center mb-24">
                <h5 className="bold">{t('portfolio:work.about')}</h5>
                <WorkShareButton agencySlug={agencySlug} workSlug={work?.slug || ''} />
              </div>
              {work ? <WorkAboutPanel agencyBrandingPlan={agencyBrandingPlan} work={work} /> : null}
            </div>
          </div>

          {hasDescriptionData ? (
            <>
              <Divider className="hide-xs" />
              <div className="px-64 px-xs-16 pt-64 pb-32">
                <h6 className="bold mb-24">{t('portfolio:work.descriptionTitle')}</h6>
                {work?.description && cleanHtml(work?.description).length ? (
                  <div className="pb-24" dangerouslySetInnerHTML={{ __html: work?.description }} />
                ) : null}
                {descriptionDetails.map((section) => {
                  return section.details && cleanHtml(section.details).length ? (
                    <Fragment key={section.header}>
                      <p className="bold">{section.header}</p>
                      <div className="pb-24" dangerouslySetInnerHTML={{ __html: section.details }} />
                    </Fragment>
                  ) : null;
                })}
              </div>
            </>
          ) : null}

          <div className="px-gt-xs-64 pb-64 hide-xs layout-row layout-align-start-stretch">
            <CtaBox
              title={t('portfolio:cta.title')}
              className="flex"
              subtitle={t('portfolio:cta.subtitle', { name: agencyName })}
              btnLabel={t('portfolio:cta.btnLabel')}
              icon={<AssignmentTwoTone />}
              longtailsPage={longtailsPage}
              element="work-modal-detail"
              agencySlug={agencySlug}
            />
          </div>

          {(similarWorks?.length ?? 0) > 0 && <Divider />}

          {/* @TODO: uncomment and add correct prop when work review is available in the db */}
          {/* <div className="px-gt-xs-32 py-64">
            <h6 className="bold mb-24">{t('portfolio:work.clientReviews')}</h6>
            <div className="border border-content-900 rounded-lg shadow-2 overflow-hidden">
              <ClientReview review={dummyReview} />
            </div>
          </div>
          <Divider /> */}

          {(similarWorks?.length ?? 0) > 0 && (
            <div className="px-64 px-xs-16 py-gt-xs-96 pt-xs-64">
              <h6 className="bold mb-24">{t('portfolio:work.mightLike')}</h6>
              <div className="layout-row layout-wrap">
                {Array.isArray(similarWorks) &&
                  similarWorks
                    .filter(({ slug }) => slug !== work?.slug)
                    .slice(-3)
                    .map((similarWork) => {
                      return (
                        <div key={similarWork.slug} className="py-8 px-8 px-xs-0 flex-100 flex-sm-50 flex-gt-sm-33">
                          <WorkCard work={similarWork} onClick={() => onClick(similarWork)} />
                        </div>
                      );
                    })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
